exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3DxsS{padding-left:20px;padding-right:20px}._2iiU9{margin:0 auto;max-width:1180px;padding:0 0 40px;text-align:center}", ""]);

// exports
exports.locals = {
	"root": "_3DxsS",
	"container": "_2iiU9"
};